import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, httpOptions } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private http: HttpClient,

        public auth: AuthService
    ) { }

    registerMerchant(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "desiredRole": "ROLE_MERCHANT",
            "deviceFingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "username": "9176270693",
            "password": "string",
            "email": "abc@gmail1.com",
            "loginToken": localStorage.getItem('loginToken'),
            "merchant": true,
        });
        const url = environment.rootCloudUrl + 'merchant/registerMerchant';



        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };

        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    registerUserAsMerchant(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "desiredRole": "ROLE_MERCHANT",
            "deviceFingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "username": localStorage.getItem('usernameOrEmail'),
            "password": "string",
            "email": "abc@gmail1.com",
            "loginToken": localStorage.getItem('loginToken'),
            "merchant": true,
        });
        const url = environment.rootCloudUrl + 'merchant/registerUserAsMerchant';



        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };

        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    addStoreAddress(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "active": true,
            "addLine1": data.addLine1,
            "addLine2": data.addLine2,
            "addState": data.addState,
            "addressType": "STORE",
            "city": data.city,
            "country": data.country,
            "createdAt": 0,
            "lastUpdated": 0,
            "lattitude": data.lattitude,
            "longitude": data.longitude,
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken'),
            },
            "zipCode": data.zipCode,
        });
        const url = environment.rootCloudUrl + 'merchant/addStoreAddress';


        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    addCustomerAddressToOrder(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "active": true,
            "addLine1": data.addLine1,
            "addLine2": data.addLine2,
            "addState": data.addState,
            "addressType": data.addressType,
            "city": data.city,
            "cartId": localStorage.getItem('cartId'),
            "storeId": localStorage.getItem('storeId'),
            "country": data.country,
            "createdAt": 0,
            "lastUpdated": 0,
            "lattitude": data.lattitude,
            "longitude": data.longitude,
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken'),
            },
            "zipCode": data.zipCode,
        });
        const url = environment.rootCloudUrl + 'store/addCustomerAddress';


        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    addNewStore(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "deviceFingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "loginToken": localStorage.getItem('loginToken'),
            "merchantPhoneNo": localStorage.getItem('usernameOrEmail'),
            "phoneNumbers": data.phoneNumbers,
            "storeAccessPassword": "string",
            "storeEmployees": data.storeEmployees,
            "storeEnabled": true,
            "storeName": data.storeName,
            "storeOwnerName": data.storeOwnerName,
            "storeRunningCost": data.storeRunningCost,
            "storeType": "FMCG"
        });
        const url = environment.rootCloudUrl + 'merchant/addNewStore';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    updateStoreTime(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "setWeekDay": data.setWeekDay,
            "storeId": localStorage.getItem('storeId'),
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken'),
            }
        });
        const url = environment.rootCloudUrl + 'merchant/updateStoreTime';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    uploadStoreProductsDownload(): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('loginToken', localStorage.getItem('loginToken'));
        formData.append('storeKey', localStorage.getItem('storeId'));
        formData.append('type', localStorage.getItem('download'));

        const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    export(link: any) {
        return this.http.get(link,
            { responseType: 'blob' });
    }

    exportImage(link: any): Observable<any> {
        let requestData = JSON.stringify({
        });

        const url = link;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    uploadStoreCategoriesUpload(event: any): Observable<any> {


        const file = event.target.files[0];
        // const file1 = event.target.files;
        let formData: FormData = new FormData();
        formData.append('loginToken', localStorage.getItem('loginToken'));
        formData.append('storeKey', localStorage.getItem('storeId'));
        formData.append('file', file);

        const url = environment.rootCloudUrl + 'store/uploadMasterCatSubCategories';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    uploadStoreProductsUpload(event: any): Observable<any> {


        const file = event.target.files[0];
        // const file1 = event.target.files;
        let formData: FormData = new FormData();
        formData.append('loginToken', localStorage.getItem('loginToken'));
        formData.append('storeKey', localStorage.getItem('storeId'));
        formData.append('file', file);

        const url = environment.rootCloudUrl + 'merchant/uploadStoreProducts';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    mapAddressToCart(data: any): Observable<any> {

        let formData: FormData = new FormData();
        formData.append('addressId', data.id);
        formData.append('cartId', data.cartId);
        const url = environment.rootCloudUrl + "store/mapAddressToCart";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };

        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    fetchHomeScreenSlides(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "fingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "loginToken": localStorage.getItem('loginToken'),
            "storeId": localStorage.getItem('storeId')
        });

        const url = environment.rootCloudUrl + "store/getUserWelcome";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getStoreCategories(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "fingerprint": {
                "createdAt": 0,
                "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                "jsonOtherInfo": "string",
                "user_id": 0
            },
            "loginToken": localStorage.getItem('loginToken'),
            "storeId": localStorage.getItem('storeId')
        });

        const url = environment.rootCloudUrl + "store/web/getStoreCategories";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    fetchHomeScreenDeals(data: any): Observable<any> {


        let formData: FormData = new FormData();
        formData.append('loginToken', localStorage.getItem('loginToken'));
        formData.append('storeId', localStorage.getItem('storeId'));


        const url = environment.rootCloudUrl + 'store/getDeals';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        // const httpOptions = {
        //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        // };
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    fetchHomeScreenFeaturedProducts(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "categoryId": "",
            "filter": "Featured",
            "pageNo": 0,
            "pageSize": 5,
            "sortOption": "productName",
            "sortOrder": "Asc",
            "storeId": localStorage.getItem('storeId'),
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });



        const url = environment.rootCloudUrl + "store/web/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }




    fetchHomeScreenHotProducts(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "categoryId": "",
            "filter": "HotSelling",
            "pageNo": 0,
            "pageSize": 5,
            "sortOption": "productName",
            "sortOrder": "Asc",
            "storeId": localStorage.getItem('storeId'),
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });



        const url = environment.rootCloudUrl + "store/web/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    fetchHomeScreenNewArrivalProducts(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "categoryId": "",
            "filter": "NewArrival",
            "pageNo": 0,
            "pageSize": 5,
            "sortOption": "productName",
            "sortOrder": "Asc",
            "storeId": localStorage.getItem('storeId'),
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });



        const url = environment.rootCloudUrl + "store/web/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getHomepage(data: any): Observable<any> {

        let requestData = JSON.stringify(
            {
                "displayPage": "Homepage",
                "storeId": localStorage.getItem('storeId'),
                "token": {
                    "fingerprint": {
                        "createdAt": 0,
                        "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                        "jsonOtherInfo": "string",
                        "user_id": 0
                    },
                    "loginToken": localStorage.getItem('loginToken')
                }
            });




        const url = environment.rootCloudUrl + "store/web/getHomepage";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    searchProductByTag(data: any): Observable<any> {

        // let requestData = JSON.stringify(
        //     {
        //         "storeId": localStorage.getItem('storeId'),
        //         "tag": data.tag
        //     });


        let formData: FormData = new FormData();

        formData.append('storeId', localStorage.getItem('storeId'));
        formData.append('tag', data.tag);



        const url = environment.rootCloudUrl + "store/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);


        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };

        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    searchProductOld(data: any): Observable<any> {

        // let requestData = JSON.stringify(
        //     {
        //         "storeId": localStorage.getItem('storeId'),
        //         "searchText": data.searchText
        //     });

        let formData: FormData = new FormData();

        formData.append('storeId', localStorage.getItem('storeId'));
        formData.append('searchText', data.searchText);




        const url = environment.rootCloudUrl + "store/web/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    searchProduct(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "categoryId": "",
            "filter": data._filter,
            "pageNo": data._page,
            "pageSize": data._limit,
            "sortOption": "productName",
            "sortOrder": "Asc",
            "storeId": localStorage.getItem('storeId'),
            "searchString": data._searchString,
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });



        const url = environment.rootCloudUrl + "store/web/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }





    getProducts(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "categoryId": data._category,
            "filter": data._filter,
            "pageNo": data._page,
            "pageSize": data._limit,
            "sortOption": "productName",
            "sortOrder": "Asc",
            "storeId": localStorage.getItem('storeId'),
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "string",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });



        const url = environment.rootCloudUrl + "store/web/searchProduct";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    getUserAddressList(data: any): Observable<any> {
        let requestData = JSON.stringify({
        });

        const url = environment.rootCloudUrl + "api/list-delivery-address";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    viewCart(data: any): Observable<any> {

        if (this.auth.user$.role_id === 11 || this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8) {
            data = {
                wholesaler_user_id: (localStorage.getItem('wholesaler_user_id')) ? (localStorage.getItem('wholesaler_user_id')) : this.auth.user$.role_id
            };
        }
        const url = environment.rootCloudUrl + "api/view-cart";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    trackTransactionDetailsPayU(data: any): Observable<any> {

        const url = environment.rootCloudUrl + "api/track-payment-payu?txnid=" + data.txnid;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    viewOutstandingDetails(data: any): Observable<any> {

        const url = environment.rootCloudUrl + "api/calculate-outstanding-amount";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    initiatePayment(data: any): Observable<any> {

        const url = environment.rootCloudUrl + "api/initiate-payment";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    initiatePaymentPayU(data: any): Observable<any> {

        const url = environment.rootCloudUrl + "api/initiate-payment-payu";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getHomePageDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/homepage?role_id=" + data.role_id + "&wholesaler_id=" + (data.wholesaler_id);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getExcerptAboutUs(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/excerpt-about-us";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getHomePageSections(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/get-page-meta-data?page_name=Homepage&role_id=" + data.role_id + "&wholesaler_id=" + (data.wholesaler_id);
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getPageSectionContent(data: any): Observable<any> {
        // const url = environment.rootCloudUrl + "api/get-page-section?section_id=" + data.id + "&role_id=" + data.role_id + "&wholesaler_id=" + (data.wholesaler_id);
       
       
        if (this.auth.user$.role_id === 11 || this.auth.user$.role_id === 7 || this.auth.user$.role_id === 8) {
               data.wholesaler_user_id= (localStorage.getItem('wholesaler_user_id')) ? (localStorage.getItem('wholesaler_user_id')) : this.auth.user$.role_id
           
        }
        const url = environment.rootCloudUrl + "api/get-page-section?section_id=" + data.id+"&wholesaler_user_id="+data?.wholesaler_user_id;

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getAgentHomePageDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/homepage?from_date=" + data.from_date + "&to_date=" + data.to_date;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getSalesUserHomePageDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/sale-users/homepage?from_date=" + data.from_date + "&to_date=" + data.to_date;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    getTargetDetails(data: any): Observable<any> {
        // const url = environment.rootCloudUrl + "api/sale-users/homepage?from_date=" + data.from_date + "&to_date=" + data.to_date;
        let url = environment.rootCloudUrl + "api/sale-user/target";

        url = environment.rootCloudUrl + "api/sale-user/target?from_date=" + data.from_date + "&to_date=" + data.to_date;

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getLoyaltyDetails(data: any): Observable<any> {
        // const url = environment.rootCloudUrl + "api/sale-users/homepage?from_date=" + data.from_date + "&to_date=" + data.to_date;
        const url = environment.rootCloudUrl + "api/view-loyalty";

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getLoyaltyStatements(data: any): Observable<any> {
        // const url = environment.rootCloudUrl + "api/sale-users/homepage?from_date=" + data.from_date + "&to_date=" + data.to_date;
        // const url = environment.rootCloudUrl + "api/view-loyalty-statement";
        const url = environment.rootCloudUrl + "api/ncpl-redemption-history";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    redeemPoints(data: any): Observable<any> {
        // const url = environment.rootCloudUrl + "api/sale-users/homepage?from_date=" + data.from_date + "&to_date=" + data.to_date;
        //const url = environment.rootCloudUrl + "api/redeem-loyalty";
        const url = environment.rootCloudUrl + "api/ncpl-redemption";

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    getAgentWholesalersDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/get-wholesalers";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    switchWholesaler(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/remove-cart";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getAgentWholesalerPageDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/view-wholesaler?id=" + data.id + "&wholesaler_company_id=" + (data.wholesaler_company_id ? (data.wholesaler_company_id) : null);

        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getAgentSubagentPageDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/view-subagent?id=" + data.id;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    approveRejectOrder(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/order/approve-reject";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    cancelOrder(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/cancel-order";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    requestToActivate(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/subagent/activate-deactivate";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    getAgentSubAgentsDetails(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/get-subagents";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.get(url, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    mappingWithWholesaler(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/agent/mapping-with-wholesaler";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    confirmInventory(cartId: any): Observable<any> {

        let requestData = cartId;

        const url = environment.rootCloudUrl + "store/confirmInventory";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }



    placeOrder(data: any): Observable<any> {
        const url = environment.rootCloudUrl + "api/update-customer-payment";
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    getOrderSummary(data: any): Observable<any> {

        let requestData = JSON.stringify({
            "pageNo": data._page,
            "pageSize": data._limit,
            "sortOption": "orderCreationDate",
            "sortOrder": "DESC",
            "storeId": localStorage.getItem('storeId'),
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });



        const url = environment.rootCloudUrl + "store/getOrderSummary";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    getOrderDetails(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "orderId": data.orderId,
            "token": {
                "fingerprint": {
                    "createdAt": 0,
                    "deviceFingerprint": localStorage.getItem('deviceFingerPrint'),
                    "jsonOtherInfo": "",
                    "user_id": 0
                },
                "loginToken": localStorage.getItem('loginToken')
            }
        });


        const url = environment.rootCloudUrl + "store/getOrderDetails";
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        //headers.append("Authorization", "Bearer " + t);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    saveCustomerOrderMessage(data: any): Observable<any> {

        const url = environment.rootCloudUrl + 'store/saveCustomerOrderMessage';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        // const httpOptions = {
        //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        // };
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    uploadImageForCart(event: any): Observable<any> {


        const file = event.target.files[0];
        // const file1 = event.target.files;
        let formData: FormData = new FormData();
        formData.append('loginToken', localStorage.getItem('loginToken'));
        formData.append('cartId', localStorage.getItem('cartId'));
        // formData.append('storeId', localStorage.getItem('storeId'));
        formData.append('file', file);



        const url = environment.rootCloudUrl + 'store/uploadImageForCart';
        var t = JSON.parse(localStorage.getItem("currentUserProfile"));
        // const httpOptions = {
        //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + t.accessToken })
        // };
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + t.accessToken })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    uploadImageForProfile(event: any): Observable<any> {
        const file = event.target.files[0];
        let formData: FormData = new FormData();
        formData.append('token', localStorage.getItem('loginToken'));
        formData.append('document_type', 'profile');
        formData.append('media', file);
        const url = environment.rootCloudUrl + 'api/upload-media';
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    uploadCameraDoc1(data: any): Observable<any> {

        let formData: FormData = new FormData();
        formData.append('token', localStorage.getItem('loginToken'));
        formData.append('document_type', 'company_document');
        formData.append('media', data.blob, data.file.name);
        formData.append('file', data.blob, data.file.name);
        const url = environment.rootCloudUrl + 'api/upload-media';
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }
    uploadCameraDoc2(data: any): Observable<any> {

        let formData: FormData = new FormData();
        formData.append('token', localStorage.getItem('loginToken'));
        formData.append('document_type', 'company_document');
        formData.append('media', data.blob, data.file.name);
        formData.append('file', data.blob, data.file.name);
        const url = environment.rootCloudUrl + 'api/upload-media';
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }

    uploadDoc1(event: any): Observable<any> {
        console.log(event);

        const file = event.target.files[0];
        console.log(file);

        let formData: FormData = new FormData();
        formData.append('token', localStorage.getItem('loginToken'));
        formData.append('document_type', 'company_document');
        formData.append('media', file);
        const url = environment.rootCloudUrl + 'api/upload-media';
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }


    uploadDoc2(event: any): Observable<any> {
        const file = event.target.files[0];
        let formData: FormData = new FormData();
        formData.append('token', localStorage.getItem('loginToken'));
        formData.append('document_type', 'personal_document');
        formData.append('media', file);
        const url = environment.rootCloudUrl + 'api/upload-media';
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, formData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }




    addToCart(data: any): Observable<any> {
        let requestData = JSON.stringify({
            "product_id": data.product_id
        }
        );
        const url = environment.rootCloudUrl + "api/add-to-cart";
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('loginToken') })
        };
        return this.http.post(url, requestData, httpOptions)
            .pipe(
                map((response: Response) => response)
            )
    }







}
